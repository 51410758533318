@import '../../variables';
@import '../../styles/common/mixins';

.title {
  @include heading-font();

  margin-bottom: 10px;
}

.addButton {
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 5px;
  max-width: 35px;
  max-height: 35px;
  font-size: 28px;
}

.filter {
  display: flex;
  margin-left: 10px;
  margin-bottom: 10px;
  gap: 20px;

  @include max-w(xs) {
    padding-top: 10px;
    flex-wrap: wrap;
  }
}

.searchByFilterContainer {
  max-width: 100% !important;
  margin: 20px 0;

  & input {
    border: 1px solid $gray-suit;
  }
}

.tableControls {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 15px 15px;
}

.resetButton {
  @include secondary-font;

  font-weight: 500;
  color: $error-red;
  margin-right: 30px;
  margin-left: auto;
}

.selectAllTitle {
  font-weight: 500;
}

.visuallyHidden {
  visibility: hidden;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.table {
  .singleSelectRow {
    grid-template-columns: 1fr 120px 70px;
  }
}

.warning {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-weight: 500;
  margin-bottom: 20px;

  & span {
    margin-left: 15px;
    color: $orange-header;
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;

  & svg {
    position: relative;
    left: 8px;
  }
}
