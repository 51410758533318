@import '../../variables';
@import '../../styles/common/mixins';

.summary {
  @include tiny-scrollbar;

  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;

  @include max-w(lg) {
    flex-direction: column;
  }

  @include max-w(xs) {
    gap: 20px;
    padding-top: 10px;
  }
}

.mainContainer {
  padding: 0 25px 0 0;
  display: flex;
  flex-direction: column;
  width: 57%;
  max-width: 885px;

  @include max-w(lg) {
    width: unset;
    padding: 0;
  }

  .topContainer {
    @include make-responsive(
      'margin-bottom',
      (
        xxl: 10px,
        xxxl: 15px,
      ),
      15px
    );

    display: flex;
    flex-direction: column;

    @include max-w(xs) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
}

.section {
  @include primary-font();

  display: flex;
  flex-direction: column;
  margin-right: 50px;
  margin-bottom: 10px;

  @include max-w(xs) {
    margin-bottom: 20px;
  }

  .expDate {
    display: flex;
    align-items: center;
    height: 40px;

    @include max-w(xs) {
      height: 30px;
    }
  }
}

.sideContainer {
  display: flex;
  flex-direction: column;
  padding: 0 0 20px 25px;
  width: 43%;
  max-width: 750px;
  gap: 30px;
  height: fit-content;

  .intervalInputContainer {
    flex-wrap: wrap;
  }

  @include max-w(lg) {
    width: unset;
    max-width: unset;
    padding: 0 0 20px;
  }
}

.inventorySidebar {
  padding: 0 !important;
  width: 100%;
}

.mainInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  &.shipping {
    @include max-w(xxl) {
      margin-bottom: 20px;
    }
  }

  @include max-w(xxl) {
    flex-direction: column;
  }

  @include max-w(xs) {
    margin-bottom: 0;
  }
}

.emailInfo {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  gap: 10px;

  .emailTop {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
  }

  &.oneLink {
    padding-left: 0;
  }

  @include max-w(xxl) {
    padding-left: 0;
    width: 100%;
  }

  @include max-w(xs) {
    margin-bottom: 20px;
    padding-left: 0;
    width: 100%;
  }
}

.emailInput {
  flex: 1;
  min-width: 260px;
}

.textareaContainer {
  margin-bottom: 30px;
  padding-bottom: 20px;

  @include make-responsive(
    'height',
    (
      xxl: 235px,
      xxxl: 300px,
    ),
    300px
  );

  @include max-w(xs) {
    margin-bottom: 20px;
    padding-bottom: 0;
    height: 395px;
  }

  .noteTextarea {
    font-family: $ff-cormorant;
  }
}

.htmlViewContainer {
  border-radius: 10px;
  padding: 40px 30px;
  background-color: $white;

  @include max-w(xxl) {
    padding: 30px 20px;
  }
}

.tooltip {
  margin-left: 10px;
  width: 10px;
  height: 10px;
  margin-bottom: 3px;
}

.tooltipText {
  @include make-responsive(
    'font-size',
    (
      xs: 14px,
      xxl: 16px,
    ),
    16px
  );

  line-height: 1.15;
  max-width: 247px;
}

.pygInfo {
  display: flex;
  align-items: center;

  .pygLabel {
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 0;
  }
}

.pygIcon {
  margin-bottom: 3px;

  @include make-responsive(
    'margin-left',
    (
      xs: 5px,
      xxl: 10px,
      xxxl: 15px,
    ),
    10px
  );

  @include make-responsive(
    'width',
    (
      xxl: 20px,
      xxxl: 25px,
    ),
    25px
  );
}

.infoBar {
  display: flex;
  flex-direction: column;

  .orderId {
    width: max-content;
  }
}

.sectionBar {
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;

  @include max-w(xxl) {
    margin-bottom: 20px;
  }
}

.pricesBar {
  display: flex;

  @include make-responsive(
    'gap',
    (
      xs: 20px,
      xxl: 35px,
      xxxl: 50px,
    ),
    50px
  );
}

.orderId {
  @include primary-font();

  color: $text-ebony;
  text-align: left;

  &::after {
    background: $text-ebony;
  }
}

.copyableButton {
  @include primary-font();

  width: fit-content;
  margin: 0 auto;
}

.dateItem {
  position: relative;
  display: flex;
  flex-direction: column;
}

.price {
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
}

.status {
  @include primary-font();
}

.linksContainer {
  display: flex;
  flex-direction: column;
  max-height: 260px;
  gap: 5px;
}

.link {
  display: flex;
  align-items: center;

  @include max-w(xs) {
    justify-content: space-between;
  }
}

.date {
  @include primary-font();
}

.linkCopyTitle {
  @include primary-font();

  margin-right: 30px;
}

.linkCopyButton {
  width: unset;
}

.actionButton {
  @include max-w(xs) {
    max-width: unset;
  }
}

.label {
  @include label();
}

.advancedSettings {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-blue;
  border-radius: 10px;
  padding: 20px;
  gap: 20px;
  margin-bottom: 20px;

  @include max-w(xs) {
    padding: 10px;
  }
}

.manageRecipientsWrapper {
  display: flex;
  flex-direction: column;
  @include make-responsive(
    'gap',
    (
      xxl: 15px,
      xxxl: 20px,
    ),
    20px,
    px
  );

  .inputLabel {
    margin: 0;
  }
}

.manageRecipients {
  display: flex;
  align-items: center;
  gap: 20px;

  .twoFATrigger {
    max-width: 180px;
  }
}

.idLabel {
  text-align: center;
}

.fixedAddressWrapper {
  height: fit-content;

  & > div:nth-child(2) > div {
    width: auto;
    margin-right: 20px;
  }
}

.fixedAddress {
  display: flex;
  align-items: center;
  justify-content: start;

  & button {
    text-align: left;
  }
}
