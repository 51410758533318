@import '../../../variables';
@import '../../../styles/common/mixins';

.form {
  & > section:not(:last-of-type) {
    @include make-responsive(
      'padding-bottom',
      (
        xxl: 30px,
        xxxl: 40px,
      ),
      40px,
      px
    );
  }

  .logos {
    margin: 0 0 20px;

    & > *:not(:last-of-type) {
      @include make-fluid('margin-right', 8px, 25px, get-breakpoint-value(xs), get-breakpoint-value(xl), 25px, px);

      @include max-w(xs) {
        margin-right: 10px;
      }
    }
  }

  .departments {
    @include max-w(xs) {
      margin-bottom: 30px;
    }

    .manageDept {
      @include max-w(xs) {
        max-width: unset;
      }
    }
  }
}

.infoIcon {
  margin-left: 10px;
  align-self: center;

  @include max-w(xs) {
    margin-left: 5px;
  }
}

.tooltipContent {
  font-weight: 400;
}

.labelWithHint {
  display: flex;

  > svg {
    order: 2;
    margin-bottom: 3px;
  }

  &:after {
    order: 1;
  }
}

.tooltipImage {
  @include max-w(xs) {
    max-width: 80vw;
    width: 100%;
  }

  & > img {
    max-width: 100%;
  }
}

.tooltipText {
  padding: 10px 20px;
}

:root {
  --upload-input-size: 224px;

  @include max-w(xxl) {
    --upload-input-size: 135px;
  }

  @include max-w(xs) {
    --upload-input-size: 102px;
  }
}

.logos {
  @include max-w(xs) {
    padding-top: 10px;
  }

  .upload {
    margin-bottom: 10px;
    min-width: var(--upload-input-size);
    max-width: var(--upload-input-size);
    min-height: var(--upload-input-size);
    max-height: var(--upload-input-size);

    svg {
      margin-bottom: 20px;

      @include max-w(xxl) {
        margin-bottom: 10px;
      }
    }

    @include max-w(xs) {
      $size: calc(50% - 5px);

      min-width: $size;
      max-width: $size;
      min-height: 44.065vw;
      max-height: 44.065vw;
    }
  }

  .rect {
    min-width: 361px;
    max-width: 361px;

    @include max-w(xxl) {
      min-width: 265px;
      max-width: 265px;
    }

    @include max-w(xs) {
      $size: 100%;

      min-width: $size;
      max-width: $size;
      min-height: $size * 0.4748;
      max-height: $size * 0.4748;

      .uploadMessage {
        display: block;
        margin: 0 auto;
        max-width: 80%;
      }
    }
  }
}

.heading {
  @include card-title-font();

  display: flex;
  margin-bottom: 20px;

  @include max-w(xs) {
    margin-bottom: 20px;
  }
}

.togglesSection {
  padding-bottom: 5px !important;

  .toggles {
    display: flex;
    flex-wrap: wrap;

    .toggle {
      width: unset;
      margin-right: 70px;

      @include max-w(map-get($breakpoints, 'xs')) {
        width: 50%;
      }
    }

    @include max-w(map-get($breakpoints, 'xxl')) {
      gap: 20px;
    }
  }

  .inputs {
    padding: 20px 0;
    .digitalBalanceInput {
      padding: 0;
    }
  }
}

.info {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  margin-left: -10px;
  margin-right: -10px;

  .previewBtn {
    @include make-responsive(
      'height',
      (
        xxl: 50px,
        xxxl: 64px,
      ),
      64px,
      px
    );

    @include make-responsive(
      'width',
      (
        xxl: 232px,
        xxxl: 298px,
      ),
      298px,
      px
    );

    @include make-responsive(
      'margin-left',
      (
        xxl: 10px,
        xxxl: 20px,
      ),
      20px,
      px
    );

    @include max-w(xs) {
      height: 56px;
      width: 100%;
      margin: 0 10px;
    }
  }
}

.orderKeyWrapper {
  display: flex;
  align-items: center;

  .orderKey {
    max-width: 140px;

    input {
      padding-left: 0.5em;
      padding-right: 0.5em;
      text-align: center;
    }

    &.valid {
      input {
        border-color: $budget-green;
      }
    }

    &.warning {
      input {
        border-color: $orange-sun;
      }
    }

    &.invalid {
      input {
        @include error-border;
      }
    }

    .statusIcon {
      top: 5px;
      right: 0;
    }

    .tooltip {
      color: $error-red;
    }

    .loader {
      top: 5px;
      right: 5px;
      left: unset;
      bottom: unset;
    }
  }

  .orderIdExample {
    @include primary-font;
    @include make-responsive(
      'margin-left',
      $values: (get-breakpoint-value(md): 20px, get-breakpoint-value(xxxl): 30px)
    );

    color: $gray-suit;
  }
}

.inputContainer {
  max-width: 33%;
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
  padding: 0 10px;

  &.selectorInput {
    width: 100%;

    @include max-w(md) {
      max-width: 50%;
    }

    @include max-w(xs) {
      max-width: inherit;
    }
  }

  &.colorPicker {
    @include max-w(xs) {
      width: 50%;
    }
  }

  &.postalCode {
    // In a default state, input has bigger padding-right due to possible icon
    input {
      padding-right: 30px;
    }
  }

  &.postalCode {
    max-width: 115px;
  }

  &.logoTypesInput {
    @include make-responsive(
      'min-width',
      (
        xxl: 406px,
        xxxl: 510px,
      ),
      510px,
      px
    );

    flex-shrink: 0;

    @include max-w(xs) {
      min-width: unset;
      width: 100%;
    }
  }

  @include max-w(md) {
    max-width: unset;
  }

  &.checkboxWrapper {
    flex-flow: row;
    align-items: center;
    @include max-w(xs) {
      width: 50%;
    }
  }
}

.orgDetails {
  .inputContainer {
    width: 100%;
  }
}

.checkbox {
  @include secondary-font();
  @include make-responsive(
    'height',
    (
      xxl: 50px,
      xxxl: 64px,
    ),
    64px
  );
}
.resetCustomizationButton {
  @include primary-font();

  border-bottom: 1px solid $ebony;
}

.accordion {
  margin-top: 40px;

  @include max-w(xxl) {
    margin-top: 30px;
  }

  @include max-w(xs) {
    margin-bottom: 30px;
  }
}

.accordionSection {
  background: transparent;

  .advancedOptionsContent {
    &:not(.nested) {
      border: 1px solid $gray-suit;
      border-left-width: 3px;
      border-radius: 5px;
      padding: 20px 0;
    }

    .nested {
      padding: 0;
    }

    > section {
      padding-left: 20px;
      padding-right: 20px;

      @include max-w(xs) {
        padding-left: 10px;
        padding-right: 10px;
      }

      &:last-child {
        margin-bottom: -10px;
      }
    }
  }
}

.advancedOptionsTitle {
  padding: 0;
  flex-flow: row-reverse;
  justify-content: flex-end;
  height: unset;
  display: flex;

  > span {
    @include max-w(xs) {
      width: min-content;
    }
  }

  > svg {
    margin-bottom: 3px;

    @include max-w(xs) {
      align-self: flex-end;
    }
  }

  &.nested {
    margin-top: 20px;

    @include max-w(xs) {
      justify-content: flex-end;
    }
  }

  svg {
    margin-right: 0;
    margin-left: 10px;
  }
}

.resetCustomizationButton {
  margin-left: 30px;
  bottom: -0.15em;

  @include max-w(xs) {
    margin-left: auto;
    align-self: flex-end;
    width: unset;
    height: unset;
  }
}

.accordionSection {
  &:not(:last-of-type) {
    @include make-responsive(
      'padding-bottom',
      (
        xxl: 30px,
        xxxl: 40px,
      ),
      30px,
      px
    );
  }

  &.emailSection {
    .checkboxWrapper {
      @include max-w(xs) {
        margin: 0;
      }
    }
  }
}

.dsCustomizer {
  @include make-responsive(
    'gap',
    (
      xxl: 20px,
      xxxl: 50px,
    ),
    20px,
    px
  );

  display: flex;
  width: 100%;

  @include max-w(lg) {
    flex-flow: column;
  }
}

.dsCustomizerFields {
  padding-bottom: 10px;
  flex: 1;
  margin-top: 10px;

  .customizerForm {
    display: flex;
    flex-direction: column;
    padding: 20px;

    @include max-w(xs) {
      padding: 10px;
    }
  }

  .tabLabel {
    @include secondary-font();
  }

  .info {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    flex-flow: column;
    align-items: stretch;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .inputContainer {
    width: 100%;
    max-width: unset;
    padding: 0;
    margin-bottom: 0;
  }

  .htmlEditor {
    border: none;
    border-radius: 10px;
    min-height: 270px;
    background: $white;
  }
}

.dsCustomizerTags {
  @include make-responsive(
    'max-width',
    (
      xxl: 298px,
      xxxl: 406px,
    ),
    298px,
    px
  );

  width: 100%;

  @include max-w(lg) {
    margin-bottom: 20px;
  }
}

.portalCheckboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  gap: 10px;

  margin-bottom: 20px;

  .inputContainer {
    max-width: 100%;

    > label {
      height: unset;

      @include max-w(xs) {
        height: 40px;
      }
    }
  }

  .checkboxWrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
    width: 100%;
  }
}

.container {
  flex-flow: column;
  max-height: 100%;
  flex: 1;
}

.tagContainer {
  padding: 10px;
}
