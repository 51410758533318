@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include make-responsive(
    'min-width',
    (
      xs: 225px,
      xxl: 300px,
      xxxl: 350px,
    )
  );
  @include make-responsive(
    'padding',
    (
      xs: 5px,
      xxl: 15px,
      xxxl: 20px,
    )
  );

  width: 100%;
}

.content {
  @include secondary-font();

  display: flex;
  flex-flow: column;

  width: 100%;
}

.title {
  @include card-title-font();

  margin-bottom: 0.6em;
}

.description {
  @include cropped-text(4);

  margin-bottom: 0.6em;
}

.heading {
  @include label();

  font-weight: 400;
}

.list {
  list-style-type: decimal;
  margin-left: 0.8em;
}

.item {
  @include secondary-font();

  .quantity {
    display: inline;
    font-weight: bold;
    margin-left: 0.25em;
  }
}

.warning {
  margin-top: 20px;
  font-weight: 500;
  color: $tangerine;

  &.noMargin {
    margin-top: 0;
  }

  @include max-w(lg) {
    font-weight: 400;
  }
}

.alert {
  color: $error-red;
  font-weight: 500;

  @include max-w(lg) {
    font-weight: 400;
  }
}

.defaultView {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 10px;
  height: 10px;

  @include max-w(xs) {
    padding: 0;
    margin: unset;
  }
}
