@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputFieldContainer {
  @include primary-font();

  width: 100%;
  position: relative;
  border: none;
  border-radius: 10px;
  padding: 5px;
  color: $ebony;
  overflow: unset;
  transition: box-shadow 0.25s ease;
  box-shadow: $transparent-shadow;
  min-height: 47px;

  .deleteButton {
    position: absolute;
    top: -4px;
    right: -4px;
    z-index: 1;

    @include max-w(xs) {
      margin: 10px 15px;
    }

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  &:hover {
    box-shadow: $input-shadow;
  }

  &.disabled {
    box-shadow: none;
  }

  &.errorBorder {
    @include error-border;
  }
}

.tagsContainer {
  @include tiny-scrollbar;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2px;
  justify-content: flex-start;
  max-height: 105px;
  overflow-y: auto;
  padding: 0;
}

.tagItem {
  @include make-responsive(
    'font-size',
    (
      xs: 14px,
      xxl: 16px,
    ),
    16px,
    px
  );

  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 2px;
  background-color: $gray-lighter;
  border-radius: 4px;
  padding: 0.3em 0.4em;
  margin: 0;
  color: unset;
  border: 0;

  .removeBtn {
    cursor: pointer;
    transition: all 0.25s ease;
    border-radius: 50%;
    width: 1.4em;
    height: 1.4em;
    flex-shrink: 0;
    background: darken($gray-lighter, 4%);
    position: relative;

    &:hover {
      background: rgbCl($error-red, 20%);

      &::after,
      &::before {
        width: 50%;
      }
    }

    &::after,
    &::before {
      content: '';
      width: 40%;
      background: $ebony;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: all 0.25s ease;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &.emptyTag {
    .removeBtn {
      display: none;
    }
  }
}

.tagInput {
  @include secondary-font();

  flex: 1 1 0;
  border: 0;
  padding: 5px;
  overflow: visible;
  width: unset;
  color: $ebony;
  margin-bottom: 0;

  input {
    padding: 0;
  }
}

.error {
  color: $error-red;
}
