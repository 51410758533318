@import '../../variables';
@import '../../styles/common/mixins';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}

.address {
  display: flex;
  flex-direction: row;
  color: $gray-suit;
}

.truncated {
  @include cropped-text(1);
}

.reset {
  color: $error-red;

  @include secondary-font;
}
