@import '../../../../variables';
@import '../../../../styles/common/mixins';

.button {
  position: relative;
  display: flex;

  @include max-w(xs) {
    width: auto;
    min-width: 32px;
  }

  .spinner {
    $size: 15px;

    max-width: $size;
    max-height: $size;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none;
  }
}
