@import '../../variables';
@import '../../styles/common/mixins';

$scrollbar-width: 4px;

.container {
  @include tiny-scrollbar($width: 5px, $thumb-color: $gray-table);

  position: fixed;
  top: 0;
  right: 0;
  z-index: 110;
  display: flex;
  flex-flow: column;
  background: $white;
  overflow-y: auto;

  @include min-w(get-breakpoint-value(xs) + 1) {
    max-width: 100%;
    bottom: 0;
    padding: 48px 0;
    width: auto;
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    height: 100vh !important;
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    width: 474px;
  }

  @include max-w(xs) {
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    width: 100vw !important;
  }
}

.overlay {
  z-index: 95;
  background: $modal-overlay;
}

.wrapper {
  padding: 0 50px;
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100vh;

  @include max-w(xs) {
    overflow-y: auto;
    padding: 50px;
  }
}

.header {
  font-weight: 700;
  margin-bottom: 15px;

  @include primary-font(
    (
      xs: 22px,
      xl: 32px,
    ),
    32px
  );
}

.text {
  margin-bottom: 15px;

  @include secondary-font();
}
