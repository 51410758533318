@import '../../styles/common/mixins';
@import '../../variables';

.availableItemsContent {
  @include make-responsive(
    'width',
    (
      lg: 340px,
      xxxl: 550px,
    ),
    500px
  );

  display: flex;
  flex-direction: column;
  padding: 0;
  max-height: 100%;
  margin-right: 40px;

  @include max-w(lg) {
    width: 550px;
    margin-bottom: 30px;
    margin-right: 0;
  }

  @include max-w(md) {
    width: auto;
  }

  @include max-w(xs) {
    @include tiny-horizontal-scrollbar();

    overflow-x: auto;
    width: auto;
  }

  .availableItemsWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include max-w(xs) {
      flex-direction: row;
    }

    .inventoryItemsWrapper {
      padding: 0;
      max-height: 100%;
      width: 100%;
      overflow-y: auto;

      @include max-w(xs) {
        width: unset;
      }

      .inventoryItemsList {
        margin-bottom: 0;
        margin-left: -10px;
        padding-right: 10px;
        max-height: 100%;

        @include max-w(xl) {
          max-height: 80vh;
        }

        @include max-w(xs) {
          margin-left: -5px;
          margin-right: -5px;
        }

        > div {
          @include max-w(xs) {
            flex-flow: column;
            position: relative;
          }

          button {
            @include max-w(xs) {
              position: absolute;
              top: 0;
              right: 0;
              transform: translate(-5px, 5px);
              width: 32px;
              height: 32px;
              border-radius: 3px;
            }
          }
        }
      }
    }

    .loadButton {
      @include make-responsive(
        'margin-top',
        (
          xs: 20px,
          xxxl: 30px,
        ),
        30px
      );

      z-index: 1;
      flex-shrink: 0;
      margin-left: auto;
      margin-right: auto;
      min-height: 50px;

      @include max-w(xs) {
        // Static height and width are needed to save size when only one loader in the button
        height: 40px;
        width: 108px;
        margin: auto;
      }

      .loader {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
      }
    }
  }
}

.searchForm {
  padding: 0 10px 10px 0;

  @include max-w(xs) {
    gap: 6px;
    padding: 10px 0 20px 0;
  }
}
