@import '../../variables';
@import '../../styles/common/mixins';

.inventoryItem {
  display: flex;
  position: relative;
  width: 100%;

  &.defaultView {
    margin-bottom: 8px;

    .price {
      @include primary-font();
    }

    .inventoryName {
      @include secondary-font();
    }

    .iconInner {
      border: 1px solid $gray-blue-light;
      width: 100px;
      height: 100px;
      min-width: 100px;

      .icon {
        width: 60%;
        height: 60%;
      }
    }
  }

  @include max-w(xs) {
    flex-direction: column;
    width: unset;
  }
}

.inventoryInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.iconInner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: 10px;

  @include make-responsive(
    'margin-right',
    (
      xs: 10px,
      xxl: 15px,
      xxxl: 20px,
    ),
    20px,
    px
  );

  @include make-responsive(
    'width',
    (
      xxl: 113px,
      xxxl: 140px,
    ),
    140px,
    px
  );

  @include make-responsive(
    'min-width',
    (
      xxl: 113px,
      xxxl: 140px,
    ),
    140px,
    px
  );

  @include make-responsive(
    'height',
    (
      xxl: 113px,
      xxxl: 140px,
    ),
    140px,
    px
  );

  @include max-w(xs) {
    min-width: 124px;
    width: 124px;
    height: 124px;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .hint {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 10px;
    height: 10px;
  }
}

.icon {
  width: 70%;
  height: 70%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.inventoryName {
  @include primary-font(
    (
      xl: 18px,
      xxxl: 22px,
    )
  );
  width: 100%;
  font-weight: 500;
  line-height: 1.15;
  color: $ebony;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.price {
  @include make-responsive(
    'font-size',
    (
      xxl: 18px,
      xxxl: 24px,
    ),
    30px,
    px
  );

  margin-bottom: 0.45em;

  font-weight: 500;
  line-height: 1.15;
  color: $ebony;

  @include max-w(xs) {
    font-size: 24px;
    margin-bottom: 0.2em;
  }
}

.mskuIcon {
  position: absolute;
  bottom: 0.6rem;
  left: 0.6rem;
  width: 0.9rem;
  height: 0.9rem;

  fill: $gray-blue;
}
