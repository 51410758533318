@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include tiny-scrollbar($width: 4px, $thumb-color: $gray-table, $track-color: transparent);

  width: 100%;
  border: 1px solid $gray-table;
  overflow-y: auto;
  max-height: 140px;
  min-height: 100px;
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 25px;

  &.maxHeight {
    max-height: 350px;
  }
}

.row {
  margin: 7px 0;
  font-weight: 500;
  display: flex;
  align-items: center;

  & svg {
    margin-right: 5px;
  }
}
