@import '../../variables';
@import '../../styles/common/mixins';

.inventoryItem {
  display: flex;
  position: relative;
  width: 100%;
  user-select: none;

  &.defaultView {
    &:not(&.isDraggable) {
      padding: 4px 10px;
    }

    .itemContentContainer {
      .price {
        @include primary-font();
      }

      .inventoryName {
        @include secondary-font();
      }
    }

    .iconInner {
      border: 1px solid $gray-blue-light;
      width: 100px;
      height: 100px;
      min-width: 100px;

      .icon {
        width: 60%;
        height: 60%;
      }
    }
  }

  &.outlined {
    border: 0.5px solid $gray-blue;
    border-radius: 10px;
    padding: 10px;
  }

  &.highlighted {
    border-color: $error-red;
  }

  &:not(&.isDraggable) {
    padding: 10px;
  }

  @include max-w(xs) {
    flex-direction: column;
    width: unset;
  }
}

.dragContainer {
  @include button-shadow;

  background: $bg-color;
  display: flex;
  border: solid 1px $gray-blue;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
  max-width: 541px;
  width: 100%;
  transition: all 0.25s ease;
}

.dragControl {
  flex-shrink: 0;
  outline: none;
  cursor: grab;

  > * {
    transition: all 0.25s ease;
  }

  &:hover > * {
    fill: $ebony;
  }

  &:active {
    cursor: grabbing;
  }
}

.itemContentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;

  .itemDataContainer {
    display: flex;
    align-self: flex-start;
    justify-content: space-between;
    position: relative;
    width: 100%;

    .inventoryInfo {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .itemData {
      display: flex;
      flex-flow: column;
    }

    .additionalData {
      display: flex;
      flex-flow: column;
      align-self: flex-start;
      justify-content: flex-end;
      gap: 5px;
      text-align: right;
      max-width: 40%;
    }
  }
}

.optionsContainer {
  @include secondary-font();

  margin-top: 5px;
}

.actions {
  margin-top: auto;
  display: flex;
  width: 100%;
}

.iconInner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: 10px;
  flex-shrink: 0;
  flex-grow: 1;
  width: 140px;
  height: 140px;

  @include make-responsive(
    'margin-right',
    (
      xs: 10px,
      xxl: 15px,
      xxxl: 20px,
    ),
    20px,
    px
  );

  @include max-w(xxl) {
    width: 130px;
    height: 130px;
  }

  @include max-w(xs) {
    width: 124px;
    height: 124px;
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.icon {
  width: 70%;
  height: 70%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.inventoryName {
  @include primary-font(
    (
      xs: 16px,
      xxl: 17px,
      xxxl: 20px,
    )
  );

  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 500;
  color: $ebony;
  text-align: left;
  word-break: break-word;
  padding-right: 0.3em;

  // mixin below should go in the end
  @include cropped-text(3);
}

.price {
  @include card-title-font();

  margin-bottom: 0.45em;

  color: $ebony;

  @include max-w(xs) {
    font-size: 22px;
    margin-bottom: 0.2em;
  }
}

.itemAdditionalInfo {
  @include make-responsive(
    'font-size',
    (
      xs: 12px,
      xxl: 16px,
      xxxl: 18px,
    ),
    18px
  );
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $gray-suit;

  &.highlighted {
    color: RGB($default-accent-color);
  }
}

.deleteButton {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 15px;
  height: 15px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
